import https from "./https"
import store from "@/store"

const product = {
  getProducts(params) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/products', {params})
  },
  getProduct(id) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/products/' + id)
  },
  getProductsOfStoreGroupbuying(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/store-groupbuying/products`, {params})
  },
  getUngroupbuyingProducts(params) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/store-groupbuying/ungroupbuying-products`, {params});
  },
  uploadImageByBase64(id, image) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/products/${id}/images/upload-by-base64`, {image})
  },
  sortImages(id, params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/products/${id}/images/sort`, params)
  },
  updateProduct(id, params) {
    const organization = store.state.general.organization
    return https.put('admin/organizations/' + organization.id + '/products/' + id, params)
  },
  createProduct(params) {
    const organization = store.state.general.organization
    return https.post('admin/organizations/' + organization.id + '/products/', params)
  },
  deleteProduct(id) {
    const organization = store.state.general.organization
    return https.delete('admin/organizations/' + organization.id + '/products/' + id)
  },
  uploadProductImages(id, params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/products/${id}/images/upload-product-images`, params);
  },
  sortImagesViaIds(id, params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/products/${id}/images/sort-via-ids`, params)
  },
  updateProductImage(productId, imageId, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/products/${productId}/images/${imageId}/update`, params)
  },
  updateProductSkuMaxQuantity(organizationId, productSkuId, params) {
    return https.put(`admin/organizations/${organizationId}/product-skus/${productSkuId}/max-quantity`, params)
  },
  syncBranchProducts(params) {
    const organization = store.state.general.organization
    return https.put('admin/organizations/' + organization.id + '/products/_sync-branch-products', params)
  },
  getSkus(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/product-skus`, { params })
  },
  getProductTypes() {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/products/types`)
  },
}

export default product
